.content{
  box-shadow: 2px 1px 15px 0px rgb(99 100 100);
  margin: 15px;
  border-radius: 20px;
  height: 70vh ;
  padding: 15px;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
  margin-bottom: 0px;
  margin-top: 0px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.imgQr{
  border: 5px solid  #58666C;
  height: 100%;
  width: 100%;
  max-width: 350px;
}
.contentImg{
  width: 100%;
  display: flex;
  justify-content: center;  
}
.text{
  color: #58666C;
  margin-bottom: 30px;
  margin-left: 30px;
  margin-top: 15px;
}
.text h2{
  font-size: 2rem;
  margin-bottom: 15px;
}
.text h3{
  font-size: 1.7rem;
}

@media only screen and (max-width: 600px) {
  .text{
    color: #58666C;
    margin-bottom: 20px;
    margin-left: 0px;
    margin-top: 10px;
  }

  .text h2{
    font-size: 1rem;
    margin-bottom: 10px;
  }
  .text h3{
    font-size: 1rem;
  }
}

