.container {
  display: block;
  margin-top: 64px;
  margin-left: 60px;
  background: rgba(247, 247, 247, 0.856);
  height: 100%;
  overflow-x: hidden;
}

.header {
  background: rgb(239, 239, 239);
  padding: 20px;
  margin: 0;
}

.shadow-box {
  -webkit-box-shadow: 2px 1px 5px 0px rgb(99, 100, 100);
  -moz-box-shadow: 2px 1px 5px 0px rgb(99, 100, 100);
  box-shadow: 2px 1px 5px 0px rgb(99, 100, 100);
}

.header > .title {
  line-height: 1px;
  color: #58666C;
}

.header > .description {
  line-height: 1px;
  color: #58666C;
}

.cards {
  margin-top: 10px;
  flex: 1;
  display: flex; 
}

.items-horizontal {
  flex-direction: row;
  flex-wrap: wrap;
}

.items-vertical {
  flex-direction: column;
  flex-wrap: wrap;
}

.card {
  background: rgb(239, 239, 239);
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
}

.circleChart {
  height: 300px; 
  width: 300px; 
}

.horizontal-charts-card {
  height: 300px; 
  width: 1000px;
}

.card-header > .title {
  line-height: 1px;
  color: #58666C;
}

.card-header > .description {
  line-height: 1px;
  color: #58666C;
}

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  outline: 0!important;
  line-height: 1.3333333;
}

.btnReverse {
  height: 50px;
  width: 160px;
  border-radius: 50px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  color: #3a3f51 !important;
  background: #ffffff;
  border-color: #ffffff;
}

.btnDefault {
  height: 50px;
  width: 160px;
  border-radius: 50px;
  cursor: pointer;
  text-transform: uppercase;
  color: #ffffff !important;
  background-color: #3a3f51;
  border-color: #3a3f51;
}

.logginButton {
  height: 35px;
  width: 160px;
  margin-top: 20px;
  border-radius: 50px;
  cursor: pointer;
  -webkit-transition: width, border-radius;
  transition: width, border-radius;
  transition-timing-function: linear;
  transition-duration: 0.5s;
  color: #ffffff !important;
  background-color: #3a3f51;
  border-color: #3a3f51;
}

.loadingButton {
  width: 35px;
  height: 35px;
  margin-top: 20px;
  border-radius: 50%;
  color: #ffffff !important;
  cursor: default;
  -webkit-transition: width, border-radius;
  transition: width, border-radius;
  transition-timing-function: linear;
  transition-duration: 0.5s;
  color: #ffffff !important;
  background-color: #3a3f51;
  border-color: #3a3f51;
}

.btn:hover {
  background-color: #2c303d;
}

.btnReverse:hover {
  background-color: #e4e4e4;
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%; 
  height: 100%;
  background: rgba(0, 0, 0, 0.397);
}

.modalContainer {
  display: flex;
  flex-direction: column;
  background: #3a3f51;
  width: 36rem;
  /* height: 20rem; */
  max-height: 23rem;
  border-radius: 50px;
  box-shadow: 8px 6px #8888889a;
}

.modalHeader {
 display: flex;
 flex-direction: row;
 justify-content: flex-start;
 height: 80px;
 padding-left: 40px;
 font-size: 24px;
 color: white;
 border-bottom: #8888889a 1px solid;
 background: rgba(250, 250, 250, 0);
}

.modalBody {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 18pt;
  color: white;
  border-bottom: #8888889a 1px solid;
  background: rgba(250, 250, 250, 0);
  padding: 10px 15px 10px 37px;
}
.link {
  color: white;
}
.modalFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 40px;
  background: rgba(250, 250, 250, 0);
  height: 100px;
}